export class JsQRScanner
{
  private scanner: any;

  public constructor(scannerProvider: ScannerProvider)
  {
    this.scanner = scannerProvider.createScanner(s => this.handleScannedText(s));
  }

  private handleScannedText(scannedText: string): void
  {
    if (this.onQRCodeScanned)
    {
      this.onQRCodeScanned(scannedText);
    }
  }

  public appendTo(htmlElement: HTMLElement): void
  {
    this.scanner.appendTo(htmlElement);
  }

  public removeFrom(htmlElement: HTMLElement)
  {
    this.scanner.removeFrom(htmlElement);
  }

  public stopScanning()
  {
    this.scanner.stopScanning();
  }

  public resumeScanning()
  {
    this.scanner.resumeScanning();
  }

  public getScanInterval(): number
  {
    return this.scanner.getScanInterval();
  }

  public setScanInterval(scanInterval: number)
  {
    this.scanner.setScanInterval(scanInterval);
  }

  public getSnapImageMaxSize(): number
  {
    return this.scanner.getSnapImageMaxSize();
  }

  public setSnapImageMaxSize(snapImageMaxSize: number)
  {
    this.scanner.setSnapImageMaxSize(snapImageMaxSize);
  }

  public isActive(): boolean
  {
    return this.scanner.isActive();
  }

  public isScanning(): boolean
  {
    return this.scanner.isScanning();
  }

  public onQRCodeScanned?(scannedText: string): void;

  public static async create(path_to_jsqrscanner_js: string): Promise<JsQRScanner>
  {
    if (!g.JsQRScanner)
    {
      loadScript(path_to_jsqrscanner_js);
    }
    return new Promise<JsQRScanner>((resolve, reject) => 
    {
      CallWhenQRSacannerReady(sp =>
      {
        const scanner = new JsQRScanner(sp);
        resolve(scanner);
      });
    });
  }
}

function loadScript(path: string)
{
  if (!hasScriptElement(path) && document)
  {
    const script = document.createElement('script');
    script.setAttribute('src', path);
    if (document.head)
    {
      document.head.appendChild(script);
    }
  }
}

function hasScriptElement(path: string): boolean
{
  if (document)
  {
    const scriptTags = document.getElementsByTagName('script');
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < scriptTags.length; ++i)
    {
      if (scriptTags[i].src.indexOf(path) !== -1)
      {
        return true;
      }
    }
  }

  return false;
}

class ScannerProvider
{
  public createScanner(onQRCodeScanned: (scannedText: string) => void): any
  {
    if (!g.JsQRScanner)
    {
      throw new Error('JsQRScanner is not ready yet!');
    }

    return new g.JsQRScanner(onQRCodeScanned);
  }
}

const g: any = window;
type TOnReady = (scannerProvider: ScannerProvider) => void;
const callbacks: TOnReady[] = [];
let scannerProvider: ScannerProvider | undefined;

function CallWhenQRSacannerReady(cb: TOnReady): void
{
  if (scannerProvider)
  {
    cb(scannerProvider);
  }
  else
  {
    callbacks.push(cb);
  }
}

function JsQRScannerReady()
{
  scannerProvider = new ScannerProvider();
  if (callbacks.length > 0)
  {
    const provider = scannerProvider;
    callbacks.forEach(cb => cb(provider));
    callbacks.splice(0, callbacks.length);
  }
}

g.JsQRScannerReady = JsQRScannerReady;
